import React from 'react'
import Projects from '../../components/Projects'

class ProjectPage extends React.Component {
  render() {
    return (
      <Projects/>
    )
  }
}

export default ProjectPage
